import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  TransactionDetail,
  ITransactionSummary,
  Transaction,
  TransactionPurchasePayload,
  TransactionSalePayload
} from '../transaction.model';
import { IApiService } from 'core/api/services/api.interface';
import { UserApiService } from 'features/user/api/api-tokens';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {
  constructor(
    @Inject(UserApiService) protected apiService: IApiService
  ) {}

  private sanitizeTransactionSummary(transactionRaw: any): ITransactionSummary { 
    const transaction = transactionRaw;
    transaction.freelyPaymentDetails = transaction?.freelyPaymentDetails || {};
    transaction.freelyPaymentDetails.customerAccountingReference = transaction?.freelyPaymentDetails?.LineItems[0]?.accountingReference || '';
    return transaction;
  }

  private sanitizeTransaction(transactionRaw: any): Transaction {
    const transaction = transactionRaw;
    transaction.timestamp = transaction?.timestamp ? new Date(transaction.timestamp) : null;
    transaction.last_modified_timestamp = transaction?.last_modified_timestamp ? new Date(transaction.last_modified_timestamp) : null;
    transaction.amount = Number(transaction?.amount);

    transaction.batch = Number(transaction?.batch);
    transaction.batchItem = Number(transaction?.batchItem);

    transaction.authorizedAmount = Number(transaction?.authorizedAmount);
    transaction.convenience_fee = Number(transaction?.convenience_fee);
    transaction.originalAmount = Number(transaction?.originalAmount);
    transaction.requestedAmount = Number(transaction?.requestedAmount);
    transaction.taxAmount = Number(transaction?.taxAmount);
    return transaction;
  }

  private sanitizeTransactionDetail(transactionRaw: any): TransactionDetail {
    console.log(transactionRaw);
    return transactionRaw;;
  }

  void(transactionId: string): Observable<any> {
    return this.apiService.delete<any>(
      'transaction',
      {
        urlParams: {  transactionId: transactionId },
      }
    ).pipe(
      map((result) => {
        console.log(result);
        return result;
      })
    );
  }

  sale(payload: TransactionSalePayload): Observable<Transaction> {
    return this.apiService.post<Transaction | any>(
      'transactionSale',
      {
        body: payload
      }
    ).pipe(
      map((transaction) => {
        if (!transaction) {
          throw new Error('Sale transaction failed');
        }

        return this.sanitizeTransaction(transaction);
      })
    );
  }

  purchase(payload: TransactionPurchasePayload): Observable<Transaction> {
    return this.apiService.post<Transaction | any>(
      'transactionPurchase',
      {
        body: payload
      }
    ).pipe(
      map((transaction) => {
        if (!transaction) {
          throw new Error('Purchase transaction is failed');
        }

        return this.sanitizeTransaction(transaction);
      })
    );
  }

  public list(): Observable<Transaction[]> {
    return this.apiService.get<Transaction[]>('transactionsList').pipe(
      map((transactions) => {

        transactions = transactions.map((transaction: any) => {
          return this.sanitizeTransaction(transaction);
        });

        return transactions;
      })
    );
  }

  public listOpen(): Observable<ITransactionSummary[]> {
    let test = this.apiService.get<ITransactionSummary[]>('transactionsListOpen', {

    }).forEach((transaction) => {console.log(transaction)});
    //console.log(test);
    return this.apiService.get<ITransactionSummary[]>('transactionsListOpen', {
      params: {

      }
    }).pipe(
      map((transactions) => {

        // transactions = transactions.map((transaction: any) => {
        //   let sanitizedTransaction = this.sanitizeTransaction(transaction);
        //   return this.sanitizeTransaction(transaction);
        // });
        // console.log(transactions);
        console.log(transactions)
        return transactions;
      })
    );
  }

  public listFailed(): Observable<ITransactionSummary[]> {
    return this.apiService.get<ITransactionSummary[]>('transactionsListFailed', {
      params: {

      }
    }).pipe(
      map((transactions) => {

        // transactions = transactions.map((ITransactionSummary: any) => {
        //  // return this.sanitizeTransaction(transaction);
        // });

        return transactions;
      })
    );
  }

  public listClosed(): Observable<ITransactionSummary[]> {
    return this.apiService.get<ITransactionSummary[]>('transactionsListClosed', {
      params: {
          includeVoided: 'true',
      }
    }).pipe(
      map((transactions) => {

        // transactions = transactions.map((transaction: any) => {
        //   return this.sanitizeTransaction(transaction);
        // });

        return transactions;
      })
    );
  }

  public listBatches(): Observable<ITransactionSummary[]> {
    return this.apiService.get<ITransactionSummary[]>('batches').pipe(
      map((transactions) => {
          console.log(transactions);
          return transactions;
        })
    );
  }

  public getTransaction(transactionId: string): Observable<TransactionDetail> {
    return this.apiService.get<TransactionDetail>('transaction', {
      urlParams: {
        transactionId: transactionId
      }
    }).pipe(
      map((transaction) => {
        return this.sanitizeTransactionDetail(transaction);// transaction; // this.sanitizeTransaction(transaction);
      })
    );
  }
}
